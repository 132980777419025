/* DASHBOARD TOTAL DATA */
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA"
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS"
export const GET_DASHBOARD_DATA_FAIL = "GET_DASHBOARD_DATA_FAIL"

/* DASHBOARD CHART DATA */
export const GET_DASHBOARD_CHART_DATA = "GET_DASHBOARD_CHART_DATA"
export const GET_DASHBOARD_CHART_DATA_SUCCESS =
  "GET_DASHBOARD_CHART_DATA_SUCCESS"
export const GET_DASHBOARD_CHART_DATA_FAIL = "GET_DASHBOARD_CHART_DATA_FAIL"
