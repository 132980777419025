import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

// E-commerce
import ecommerce from "./e-commerce/reducer"

// Events
import events from "./events/reducer"

// Users
import users from "./users/reducer"

// Booked Event
import bookedEvents from "./bookedEvent/reducer"

// Calendar
// import calendar from "./calendar/reducer"

// Chat
import chat from "./chat/reducer"

// Crypto
import crypto from "./crypto/reducer"

// Invoices
import invoices from "./invoices/reducer"

// Jobs
import JobReducer from "./jobs/reducer"

// Projects
import projects from "./projects/reducer"

// Tasks
import tasks from "./tasks/reducer"

// Contacts
import contacts from "./contacts/reducer"

// Mails
import mails from "./mails/reducer"

// Dashboard
import dashboard from "./dashboard/reducer"

// //Dasboard saas
// import DashboardSaas from "./dashboard-saas/reducer"

// //Dasboard crypto
// import DashboardCrypto from "./dashboard-crypto/reducer"

// //Dasboard blog
// import DashboardBlog from "./dashboard-blog/reducer"

// //Dasboard job
// import DashboardJob from "./dashboard-jobs/reducer"

// Purchase
import Purchase from "./purchase/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  events,
  bookedEvents,
  users,
  // calendar,
  chat,
  mails,
  crypto,
  invoices,
  JobReducer,
  projects,
  tasks,
  contacts,
  dashboard,
  // DashboardSaas,
  // DashboardCrypto,
  // DashboardBlog,
  // DashboardJob,
  Purchase,
})

export default rootReducer
