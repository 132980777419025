import { call, put, takeEvery } from "redux-saga/effects"
import { GET_DASHBOARD_CHART_DATA, GET_DASHBOARD_DATA } from "./actionTypes"
import {
  getDashboardDataSuccess,
  getDashboardDataFail,
  getDashboardChartDataSuccess,
  getDashboardChartDataFail,
} from "./actions"

import { get, del } from "helpers/api_helper"

function* fetchDashboardData() {
  try {
    const response = yield call(get, "/api/v1/admin/users/dashboard-counts/")
    yield put(getDashboardDataSuccess(response))
  } catch (error) {
    yield put(getDashboardDataFail(error))
  }
}

function* fetchDashboardChartData() {
  try {
    const response = yield call(get, "/api/v1/admin/event/dashboard-details/")

    yield put(getDashboardChartDataSuccess(response))
  } catch (error) {
    yield put(getDashboardChartDataFail(error))
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_DASHBOARD_DATA, fetchDashboardData)
  yield takeEvery(GET_DASHBOARD_CHART_DATA, fetchDashboardChartData)
}

export default dashboardSaga
