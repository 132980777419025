const {
  GET_PURCHASEEVENT,
  GET_PURCHASEEVENT_SUCCESS,
  GET_PURCHASEEVENT_FAIL,
  GET_PURCHASEEVENT_APPRENTICE_DETAIL,
  GET_PURCHASEEVENT_APPRENTICE_DETAIL_SUCCESS,
  GET_PURCHASEEVENT_APPRENTICE_DETAIL_FAIL,
  DELETE_PURCHASEEVENT,
  DELETE_PURCHASEEVENT_SUCCESS,
  DELETE_PURCHASEEVENT_FAIL,
} = require("./actionTypes")

export const getPurchaseEvent = () => ({
  type: GET_PURCHASEEVENT,
})

export const getPurchaseEventSuccess = booked => ({
  type: GET_PURCHASEEVENT_SUCCESS,
  payload: booked,
})

export const getPurhcaseEventFail = error => ({
  type: GET_PURCHASEEVENT_FAIL,
  payload: error,
})

export const getPurhcaseEventApprentice = id => ({
  type: GET_PURCHASEEVENT_APPRENTICE_DETAIL,
  payload: id,
})

export const getPurhcaseEventApprenticeSuccess = booked => ({
  type: GET_PURCHASEEVENT_APPRENTICE_DETAIL_SUCCESS,
  payload: booked,
})

export const getPurhcaseEventApprenticeFail = error => ({
  type: GET_PURCHASEEVENT_APPRENTICE_DETAIL_FAIL,
  payload: error,
})

export const deletePurchaseEvent = id => ({
  type: DELETE_PURCHASEEVENT,
  payload: id,
})

export const deletePurchaseEventSuccess = event => ({
  type: DELETE_PURCHASEEVENT_SUCCESS,
  payload: event,
})

export const deletePurchaseEventFail = error => ({
  type: DELETE_PURCHASEEVENT_FAIL,
  payload: error,
})
