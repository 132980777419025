import {
  GET_EVENTS,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAIL,
  GET_EVENT_DETAIL,
  GET_EVENT_DETAIL_SUCCESS,
  GET_EVENT_DETAIL_FAIL,
  DELETE_EVENT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  UPDATE_EVENT,
  UPDATE_EVENT_FAIL,
  UPDATE_EVENT_SUCCESS,
} from "./actionTypes"

export const getEvents = () => ({
  type: GET_EVENTS,
})

export const getEventsSuccess = events => ({
  type: GET_EVENTS_SUCCESS,
  payload: events,
})

export const getEventsFail = error => ({
  type: GET_EVENTS_FAIL,
  payload: error,
})

export const updateEvent = (eventId, value) => ({
  type: UPDATE_EVENT,
  payload: { eventId, value },
})

export const updateEventSuccess = event => ({
  type: UPDATE_EVENT_SUCCESS,
  payload: event,
})

export const updateEventFail = error => ({
  type: UPDATE_EVENT_FAIL,
  payload: error,
})

export const getEventDetail = id => ({
  type: GET_EVENT_DETAIL,
  payload: id,
})

export const getEventDetailSuccess = event => ({
  type: GET_EVENT_DETAIL_SUCCESS,
  payload: event,
})

export const getEventDetailFail = error => ({
  type: GET_EVENT_DETAIL_FAIL,
  payload: error,
})

export const deleteEvent = id => ({
  type: DELETE_EVENT,
  payload: id,
})

export const deleteEventSuccess = event => ({
  type: DELETE_EVENT_SUCCESS,
  payload: event,
})

export const deleteEventFail = error => ({
  type: DELETE_EVENT_FAIL,
  payload: error,
})

// export const getProducts = () => ({
//   type: GET_PRODUCTS,
// })

// export const getProductsSuccess = products => ({
//   type: GET_PRODUCTS_SUCCESS,
//   payload: products,
// })

// export const getProductsFail = error => ({
//   type: GET_PRODUCTS_FAIL,
//   payload: error,
// })

// export const onLikeComment = (commentId, productId) => ({
//   type: ON_LIKE_COMMENT,
//   payload: { commentId, productId },
// })

// export const onLikeCommentSuccess = comments => ({
//   type: ON_LIKE_COMMENT_SUCCESS,
//   payload: comments,
// })

// export const onLikeCommentFail = error => ({
//   type: ON_LIKE_COMMENT_FAIL,
//   payload: error,
// })

// export const onLikeReply = (commentId, productId, replyId) => ({
//   type: ON_LIKE_REPLY,
//   payload: { commentId, productId, replyId },
// })

// export const onLikeReplySuccess = comments => ({
//   type: ON_LIKE_REPLY_SUCCESS,
//   payload: comments,
// })

// export const onLikeReplyFail = error => ({
//   type: ON_LIKE_REPLY_FAIL,
//   payload: error,
// })

// export const onAddReply = (commentId, productId, replyText) => ({
//   type: ON_ADD_REPLY,
//   payload: { commentId, productId, replyText },
// })

// export const onAddReplySuccess = comments => ({
//   type: ON_ADD_REPLY_SUCCESS,
//   payload: comments,
// })

// export const onAddReplyFail = error => ({
//   type: ON_ADD_REPLY_FAIL,
//   payload: error,
// })
