import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import { isEmpty } from "lodash"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import { deleteUser, getUserDetail, getUsers, updateUserr } from "store/actions"

import {
  OrderId,
  EventName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
  CommonField,
  Image,
  SwitchField,
} from "./UserCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"
import { events } from "common/data"
import EcommerceOrdersModal from "pages/Ecommerce/EcommerceOrders/EcommerceOrdersModal"
import { Alert } from "bootstrap"

function Events() {
  const navigate = useNavigate()
  //meta title
  document.title = "Users | Tacticulture-Admin"

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [orderList, setOrderList] = useState([])
  const [user, setUser] = useState(null)

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderId: (user && user.orderId) || "",
      eventName: (user && user.eventName) || "",
      orderdate: (user && user.orderdate) || "",
      total: (user && user.total) || "",
      paymentStatus: (user && user.paymentStatus) || "Paid",
      badgeclass: (user && user.badgeclass) || "success",
      paymentMethod: (user && user.paymentMethod) || "Mastercard",
    },
    validationSchema: Yup.object({
      orderId: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Order Id")
        .required("Please Enter Your Order Id"),
      eventName: Yup.string().required("Please Enter Your Event Name"),
      orderdate: Yup.string().required("Please Enter Your Order Date"),
      total: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Amount")
        .required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateOrder = {
          id: user ? user.id : 0,
          orderId: values.orderId,
          eventName: values.eventName,
          orderdate: values.orderdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        }
        // update user
        dispatch(onUpdateOrder(updateOrder))
        validation.resetForm()
      } else {
        const newOrder = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values["orderId"],
          eventName: values["eventName"],
          orderdate: values["orderdate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        }
        // save new user
        dispatch(onAddNewOrder(newOrder))
        validation.resetForm()
      }
      toggle()
    },
  })

  const toggleViewModal = id => {
    dispatch(getUserDetail(id))
    navigate("/user-detail")
  }

  const dispatch = useDispatch()
  const { users } = useSelector(state => ({
    users: state.users.users,
  }))

  useEffect(() => {
    if (users && !users.length) {
      dispatch(getUsers())
    }
  }, [dispatch])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setUser(null)
    } else {
      setModal(true)
    }
  }

  const handleOrderClick = arg => {
    const user = arg
    setUser({
      id: user.id,
      orderId: user.orderId,
      eventName: user.eventName,
      orderdate: user.orderdate,
      total: user.total,
      paymentStatus: user.paymentStatus,
      paymentMethod: user.paymentMethod,
      badgeclass: user.badgeclass,
    })

    setIsEdit(true)

    toggle()
  }

  //delete user
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = user => {
    setUser(user)
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (user && user.id) {
      dispatch(deleteUser(user.id))
      setDeleteModal(false)
    }
  }
  const handleOrderClicks = () => {
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  const columns = useMemo(
    () => [
      {
        Header: "User ID",
        accessor: "id",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "First Name",
        accessor: "first_name",
        filterable: true,
        Cell: cellProps => {
          return <CommonField {...cellProps} />
        },
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        filterable: true,
        Cell: cellProps => {
          return <CommonField {...cellProps} />
        },
      },
      {
        Header: "Profile",
        accessor: "default_profile",
        filterable: true,
        Cell: cellProps => {
          return <CommonField {...cellProps} />
        },
      },
      {
        Header: "Image",
        accessor: "profile_image",
        filterable: true,
        Cell: cellProps => {
          return <Image {...cellProps} />
        },
      },
      {
        Header: "User Status",
        accessor: "is_active",
        filterable: true,
        Cell: cellProps => {
          return (
            <SwitchField
              {...cellProps}
              onChange={value =>
                dispatch(updateUserr(cellProps.row.original.id, value))
              }
            />
          )
        },
      },

      {
        Header: "View Details",
        accessor: "view",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => toggleViewModal(cellProps.row.original.id)}
            >
              View Details
            </Button>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {/* <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original
                  handleOrderClick(orderData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link> */}
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDelete(orderData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    [],
  )

  return (
    <React.Fragment>
      {/* <EventsDetailModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <h3 className="mb-4">Manage Users</h3>
          {/* <Breadcrumbs title="Users" breadcrumbItem="List" /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    className="userTable"
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                    forFilter={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Order" : "Add Order"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Order Id</Label>
                      <Input
                        name="orderId"
                        type="text"
                        placeholder="Insert Order Id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.orderId || ""}
                        invalid={
                          validation.touched.orderId &&
                          validation.errors.orderId
                            ? true
                            : false
                        }
                      />
                      {validation.touched.orderId &&
                      validation.errors.orderId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.orderId}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">First Name</Label>
                      <Input
                        name="first_name"
                        type="text"
                        placeholder="Insert Event Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.eventName || ""}
                        invalid={
                          validation.touched.eventName &&
                          validation.errors.eventName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.eventName &&
                      validation.errors.eventName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.eventName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Order Date</Label>
                      <Input
                        name="orderdate"
                        type="date"
                        // value={orderList.orderdate || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.orderdate || ""}
                        invalid={
                          validation.touched.orderdate &&
                          validation.errors.orderdate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.orderdate &&
                      validation.errors.orderdate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.orderdate}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Total</Label>
                      <Input
                        name="total"
                        type="text"
                        placeholder="Insert Total Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.total || ""}
                        invalid={
                          validation.touched.total && validation.errors.total
                            ? true
                            : false
                        }
                      />
                      {validation.touched.total && validation.errors.total ? (
                        <FormFeedback type="invalid">
                          {validation.errors.total}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Payment Status</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentStatus || ""}
                      >
                        <option>Paid</option>
                        <option>Chargeback</option>
                        <option>Refund</option>
                      </Input>
                      {validation.touched.paymentStatus &&
                      validation.errors.paymentStatus ? (
                        <FormFeedback type="invalid">
                          {validation.errors.paymentStatus}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Badge Class</Label>
                      <Input
                        name="badgeclass"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.badgeclass || ""}
                      >
                        <option>success</option>
                        <option>danger</option>
                        <option>warning</option>
                      </Input>
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Payment Method</Label>
                      <Input
                        name="paymentMethod"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentMethod || ""}
                      >
                        <option>Mastercard</option>
                        <option>Visa</option>
                        <option>Paypal</option>
                        <option>COD</option>
                      </Input>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}
// EcommerceOrder.propTypes = {
//   preGlobalFilteredRows: PropTypes.any,
// }

export default Events
