import {
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAIL,
  GET_DASHBOARD_CHART_DATA_SUCCESS,
  GET_DASHBOARD_CHART_DATA_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dashboardData: {},
  dashboardDataError: {},
  dashboardChartData: [],
  dashboardChartDataError: {},
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload,
      }
    case GET_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        dashboardDataError: action.payload,
      }

    case GET_DASHBOARD_CHART_DATA_SUCCESS:
      return {
        ...state,
        dashboardChartData: action.payload,
      }
    case GET_DASHBOARD_CHART_DATA_FAIL:
      return {
        ...state,
        dashboardChartDataError: action.payload,
      }
    default:
      return state
  }
}

export default Dashboard
