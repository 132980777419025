import React from "react"
import { Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"

export default function UserDetails() {
  const { userDetail } = useSelector(state => ({
    userDetail: state.users.userDetail,
  }))
  // console.log(userDetail, "op")
  return (
    <>
      <div className="boxed">
        <div className="heading">
          <h3>User Details</h3>
        </div>
        <Row className="muiTag">
          <Col md={12}>
            <Col>
              <img src={userDetail.profile_image} alt="" />
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Full Name</h4>
              <span>{userDetail.username}</span>
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Email Address:</h4>
              <span>{userDetail.email}</span>
            </Col>
          </Col>

          <Col md={4}>
            <Col>
              <h4>Phone Number:</h4>
              <span>{userDetail.phone_number}</span>
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Category:</h4>
              <span>
                {userDetail.events?.map(el => {
                  return (
                    <div key={el.id}>
                      <p>{el.event_categories}</p>
                    </div>
                  )
                })}
              </span>
            </Col>
          </Col>
        </Row>
      </div>
    </>
  )
}
