import React, { Component } from "react"
import Leaflet from "leaflet"
// import { Map, TileLayer, Marker } from "react-leaflet"
import "../../../../node_modules/leaflet/dist/leaflet.css"

Leaflet.Icon.Default.imagePath = "../node_modules/leaflet"

delete Leaflet.Icon.Default.prototype._getIconUrl

Leaflet.Icon.Default.mergeOptions({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
})

export default class SimpleMap extends Component {
  // state = {
  //   lat: 51.505,
  //   lng: -0.09,
  //   zoom: 13,
  // }

  render() {
    const position = [this.state.lat, this.state.lng]
    return (
      <>
        <h1>Simple Map</h1>
        {/* <Map
          center={position}
          zoom={this.state.zoom}
          style={{ height: "300px" }}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position}></Marker>
        </Map> */}
      </>
    )
  }
}
