import moment from "moment"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import ProfileImage from "../../../assets/images/userProfile.svg"

export default function BookedDetails() {
  const { bookedEventDetail } = useSelector(state => ({
    bookedEventDetail: state.bookedEvents.bookedEventDetail,
  }))
  // console.log(bookedEventDetail, "mu d")
  return (
    <>
      <div className="boxed">
        <div className="heading">
          <h3>Booked Event Details</h3>
        </div>
        <Row className="muiTag">
          <Col md={12}>
            <Col>
              <img
                src={
                  bookedEventDetail.created_by?.profile_image
                    ? bookedEventDetail.created_by?.profile_image
                    : ProfileImage
                }
                alt=""
              />
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Event Name</h4>
              <span>{bookedEventDetail.event}</span>
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Created By</h4>
              {bookedEventDetail?.instructor_details?.map(items => (
                <span key={items.id}>
                  {items.first_name} {items.last_name}
                </span>
              ))}
            </Col>
          </Col>

          <Col md={4}>
            <Col>
              <h4>Booked By</h4>
              <span>
                {bookedEventDetail.created_by?.first_name}{" "}
                {bookedEventDetail.created_by?.last_name}
              </span>
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Booking Type</h4>
              <span>{bookedEventDetail.booking_type}</span>
            </Col>
          </Col>

          <Col md={4}>
            <Col>
              <h4>Booking Date</h4>
              <span>{bookedEventDetail.booking_date}</span>
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Booking Time</h4>
              <span>
                {moment(bookedEventDetail.booking_time, "hh:mm:ss").format(
                  "hh:mm A"
                )}
              </span>
            </Col>
          </Col>
        </Row>
      </div>
    </>
  )
}
