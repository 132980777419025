import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  DELETE_USER,
  GET_USER_DETAIL,
  UPDATE_USERR,
} from "./actionTypes"
import {
  getUsers,
  getUsersSuccess,
  getUsersFail,
  getUserDetailSuccess,
  deleteUserSuccess,
  deleteUserFail,
  getUserDetailFail,
  updateUserrSuccess,
  updateUserrFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getEventFullDetail,
  getEventsFullData,
} from "helpers/fakebackend_helper"
import { del, get, putt } from "helpers/api_helper"

function* fetchUsers() {
  try {
    const response = yield call(get, "/api/v1/admin/users/user/")

    yield put(getUsersSuccess(response.results))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* updateUser({ payload: data }) {
  try {
    const response = yield call(
      putt,
      `/api/v1/admin/users/change-user-status/${data.userId}/`,
      { is_active: data.value }
    )
    yield put(updateUserrSuccess(data))
  } catch (error) {
    yield put(updateUserrFail(error))
  }
}

function* fetchUserDetail({ payload: id }) {
  try {
    const response = yield call(get, `/api/v1/admin/users/manage-users/${id}`)

    yield put(getUserDetailSuccess(response))
  } catch (error) {
    yield put(getUserDetailFail(error))
  }
}
function* onDeleteUser({ payload: user }) {
  try {
    const response = yield call(del, `/api/v1/admin/users/manage-users/${user}`)
    yield put(deleteUserSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

// function* fetchProducts() {
//   try {
//     const response = yield call(getProducts)
//     yield put(getProductsSuccess(response))
//   } catch (error) {
//     yield put(getProductsFail(error))
//   }
// }

// function* fetchProductDetail({ productId }) {
//   try {
//     const response = yield call(getProductDetail, productId)
//     yield put(getProductDetailSuccess(response))
//   } catch (error) {
//     yield put(getProductDetailFail(error))
//   }
// }

// function* fetchOrders() {
//   try {
//     const response = yield call(getOrders)
//     yield put(getOrdersSuccess(response))
//   } catch (error) {
//     yield put(getOrdersFail(error))
//   }
// }

// function* fetchCartData() {
//   try {
//     const response = yield call(getCartData)
//     yield put(getCartDataSuccess(response))
//   } catch (error) {
//     yield put(getCartDataFail(error))
//   }
// }

// function* fetchCustomers() {
//   try {
//     const response = yield call(getCustomers)
//     yield put(getCustomersSuccess(response))
//   } catch (error) {
//     yield put(getCustomersFail(error))
//   }
// }

// function* onUpdateCustomer({ payload: customer }) {
//   try {
//     const response = yield call(updateCustomer, customer)
//     yield put(updateCustomerSuccess(response))
//   } catch (error) {
//     yield put(updateCustomerFail(error))
//   }
// }

// function* onDeleteCustomer({ payload: customer }) {
//   try {
//     const response = yield call(deleteCustomer, customer)
//     yield put(deleteCustomerSuccess(response))
//   } catch (error) {
//     yield put(deleteCustomerFail(error))
//   }
// }

// function* onAddNewCustomer({ payload: customer }) {
//   try {
//     const response = yield call(addNewCustomer, customer)

//     yield put(addCustomerSuccess(response))
//   } catch (error) {
//     yield put(addCustomerFail(error))
//   }
// }

// function* fetchShops() {
//   try {
//     const response = yield call(getShops)
//     yield put(getShopsSuccess(response))
//   } catch (error) {
//     yield put(getShopsFail(error))
//   }
// }

// function* onUpdateOrder({ payload: order }) {
//   try {
//     const response = yield call(updateOrder, order)
//     yield put(updateOrderSuccess(response))
//   } catch (error) {
//     yield put(updateOrderFail(error))
//   }
// }

// function* onDeleteOrder({ payload: order }) {
//   try {
//     const response = yield call(deleteOrder, order)
//     yield put(deleteOrderSuccess(response))
//   } catch (error) {
//     yield put(deleteOrderFail(error))
//   }
// }

// function* onAddNewOrder({ payload: order }) {
//   try {
//     const response = yield call(addNewOrder, order)
//     yield put(addOrderSuccess(response))
//   } catch (error) {
//     yield put(addOrderFail(error))
//   }
// }

// function* getProductComents() {
//   try {
//     // todo - add product Id to the payload and api
//     const response = yield call(getProductComentsApi)
//     yield put(getProductCommentsSuccess(response))
//   } catch (error) {
//     yield put(getProductCommentsFail(error))
//   }
// }

// function* onLikeComment({ payload: { commentId, productId } }) {
//   try {
//     // todo - add product Id to the payload and api
//     const response = yield call(onLikeCommentApi, commentId, productId)
//     yield put(onLikeCommentSuccess(response))
//   } catch (error) {
//     yield put(onLikeCommentFail(error))
//   }
// }

// function* onLikeReply({ payload: { commentId, productId, replyId } }) {
//   try {
//     // todo - add product Id to the payload and api
//     const response = yield call(onLikeReplyApi, commentId, productId, replyId)
//     yield put(onLikeReplySuccess(response))
//   } catch (error) {
//     yield put(onLikeReplyFail(error))
//   }
// }

// function* onAddReply({ payload: { commentId, productId, replyText } }) {
//   try {
//     const response = yield call(onAddReplyApi, commentId, productId, replyText)
//     yield put(onAddReplySuccess(response))
//   } catch (error) {
//     yield put(onAddReplyFail(error))
//   }
// }

// function* onAddComment({ payload: { productId, commentText } }) {
//   try {
//     const response = yield call(onAddCommentApi, productId, commentText)
//     yield put(onAddCommentSuccess(response))
//   } catch (error) {
//     yield put(onAddCommentFail(error))
//   }
// }

function* userSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_DETAIL, fetchUserDetail)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(UPDATE_USERR, updateUser)
  // yield takeEvery(GET_PRODUCTS, fetchProducts)
  // yield takeEvery(GET_PRODUCT_DETAIL, fetchProductDetail)
  // yield takeEvery(GET_ORDERS, fetchOrders)
  // yield takeEvery(GET_CART_DATA, fetchCartData)
  // yield takeEvery(GET_CUSTOMERS, fetchCustomers)
  // yield takeEvery(ADD_NEW_CUSTOMER, onAddNewCustomer)
  // yield takeEvery(UPDATE_CUSTOMER, onUpdateCustomer)
  // yield takeEvery(DELETE_CUSTOMER, onDeleteCustomer)
  // yield takeEvery(GET_SHOPS, fetchShops)
  // yield takeEvery(ADD_NEW_ORDER, onAddNewOrder)
  // yield takeEvery(UPDATE_ORDER, onUpdateOrder)

  // yield takeEvery(GET_PRODUCT_COMMENTS, getProductComents)
  // yield takeEvery(ON_LIKE_COMMENT, onLikeComment)
  // yield takeEvery(ON_LIKE_REPLY, onLikeReply)
  // yield takeEvery(ON_ADD_REPLY, onAddReply)
  // yield takeEvery(ON_ADD_COMMENT, onAddComment)
}

export default userSaga
