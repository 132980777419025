import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  UPDATE_USERR__SUCCESS,
  UPDATE_USERR_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  usersError: {},
  userDetail: {},
  userDetailError: {},
  userDeleteError: {},
}

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }
    case GET_USERS_FAIL:
      return {
        ...state,
        usersError: action.payload,
      }

    case UPDATE_USERR__SUCCESS:
      return {
        ...state,
        users: state.users.map(el =>
          el.id === action.payload.userId
            ? { ...el, is_active: action.payload.value }
            : el
        ),
      }
    case UPDATE_USERR_FAIL:
      return {
        ...state,
      }
    case GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: action.payload,
      }
    case GET_USER_DETAIL_FAIL:
      return {
        ...state,
        userDetailError: action.payload,
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
      }
    case DELETE_USER_FAIL:
      return {
        ...state,
        userDeleteError: action.payload,
      }

    default:
      return state
  }
}

export default Users
