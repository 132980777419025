import React, { Component } from "react"
import Leaflet from "leaflet"
// import {
//   Circle,
//   FeatureGroup,
//   LayerGroup,
//   LayersControl,
//   Map,
//   Marker,
//   Popup,
//   Rectangle,
//   TileLayer,
// } from "react-leaflet"
import "../../../../node_modules/leaflet/dist/leaflet.css"

Leaflet.Icon.Default.imagePath = "../node_modules/leaflet"

delete Leaflet.Icon.Default.prototype._getIconUrl

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})

// const { BaseLayer, Overlay } = LayersControl

const rectangle = [
  [51.49, -0.08],
  [51.5, -0.06],
]

export default class MapLayerControl extends Component {
  // state = {
  //   lat: 51.505,
  //   lng: -0.09,
  //   zoom: 13,
  // }

  render() {
    const center = [this.state.lat, this.state.lng]
    return (
      <>
        <h1>Map Layer Control</h1>
        {/* <Map center={center} zoom={this.state.zoom} style={{ height: "300px" }}>
          <LayersControl position="topright">
            <BaseLayer checked name="OpenStreetMap.Mapnik">
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </BaseLayer>
            <BaseLayer name="OpenStreetMap.BlackAndWhite">
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
              />
            </BaseLayer>
            <Overlay name="Marker with popup">
              <Marker position={center}>
                <Popup>
                  A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
              </Marker>
            </Overlay>
            <Overlay checked name="Layer group with circles">
              <LayerGroup>
                <Circle center={center} fillColor="blue" radius={200} />
                <Circle
                  center={center}
                  fillColor="red"
                  radius={100}
                  stroke={false}
                />
                <LayerGroup>
                  <Circle
                    center={[51.51, -0.08]}
                    color="green"
                    fillColor="green"
                    radius={100}
                  />
                </LayerGroup>
              </LayerGroup>
            </Overlay>
            <Overlay name="Feature group">
              <FeatureGroup color="purple">
                <Popup>Popup in FeatureGroup</Popup>
                <Circle center={[51.51, -0.06]} radius={200} />
                <Rectangle bounds={rectangle} />
              </FeatureGroup>
            </Overlay>
          </LayersControl>
        </Map> */}
      </>
    )
  }
}
