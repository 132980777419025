import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_EVENTS,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAIL,
  DELETE_EVENT,
  GET_EVENT_DETAIL,
  UPDATE_EVENT,
} from "./actionTypes"
import {
  getEvents,
  getEventsSuccess,
  getEventsFail,
  getEventDetailSuccess,
  deleteEventSuccess,
  deleteEventFail,
  getEventDetailFail,
  updateEventSuccess,
  updateEventFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getEventFullDetail,
  getEventsFullData,
} from "helpers/fakebackend_helper"
import { get, del, putt } from "helpers/api_helper"

function* fetchEvents() {
  try {
    const response = yield call(get, "/api/v1/admin/event/event-list/")

    yield put(getEventsSuccess(response.results))
  } catch (error) {
    yield put(getEventsFail(error))
  }
}

function* updateEvent({ payload: data }) {
  try {
    const response = yield call(
      putt,
      `/api/v1/admin/event/change-events-status/${data.eventId}/`,
      { is_event_active: data.value }
    )
    yield put(updateEventSuccess(data))
  } catch (error) {
    yield put(updateEventFail(error))
  }
}

function* fetchEventDetail({ payload: event }) {
  try {
    const response = yield call(
      get,
      `/api/v1/admin/event/manage-event/${event}`
    )

    yield put(getEventDetailSuccess(response))
  } catch (error) {
    yield put(getEventDetailFail(error))
  }
}

function* onDeleteEvent({ payload: event }) {
  try {
    const response = yield call(
      del,
      `/api/v1/admin/event/manage-event/${event}`
    )

    yield put(deleteEventSuccess(event))
  } catch (error) {
    yield put(deleteEventFail(error))
  }
}

// function* fetchProducts() {
//   try {
//     const response = yield call(getProducts)
//     yield put(getProductsSuccess(response))
//   } catch (error) {
//     yield put(getProductsFail(error))
//   }
// }

// function* fetchProductDetail({ productId }) {
//   try {
//     const response = yield call(getProductDetail, productId)
//     yield put(getProductDetailSuccess(response))
//   } catch (error) {
//     yield put(getProductDetailFail(error))
//   }
// }

// function* fetchOrders() {
//   try {
//     const response = yield call(getOrders)
//     yield put(getOrdersSuccess(response))
//   } catch (error) {
//     yield put(getOrdersFail(error))
//   }
// }

// function* fetchCartData() {
//   try {
//     const response = yield call(getCartData)
//     yield put(getCartDataSuccess(response))
//   } catch (error) {
//     yield put(getCartDataFail(error))
//   }
// }

// function* fetchCustomers() {
//   try {
//     const response = yield call(getCustomers)
//     yield put(getCustomersSuccess(response))
//   } catch (error) {
//     yield put(getCustomersFail(error))
//   }
// }

// function* onUpdateCustomer({ payload: customer }) {
//   try {
//     const response = yield call(updateCustomer, customer)
//     yield put(updateCustomerSuccess(response))
//   } catch (error) {
//     yield put(updateCustomerFail(error))
//   }
// }

// function* onDeleteCustomer({ payload: customer }) {
//   try {
//     const response = yield call(deleteCustomer, customer)
//     yield put(deleteCustomerSuccess(response))
//   } catch (error) {
//     yield put(deleteCustomerFail(error))
//   }
// }

// function* onAddNewCustomer({ payload: customer }) {
//   try {
//     const response = yield call(addNewCustomer, customer)

//     yield put(addCustomerSuccess(response))
//   } catch (error) {
//     yield put(addCustomerFail(error))
//   }
// }

// function* fetchShops() {
//   try {
//     const response = yield call(getShops)
//     yield put(getShopsSuccess(response))
//   } catch (error) {
//     yield put(getShopsFail(error))
//   }
// }

// function* onUpdateOrder({ payload: order }) {
//   try {
//     const response = yield call(updateOrder, order)
//     yield put(updateOrderSuccess(response))
//   } catch (error) {
//     yield put(updateOrderFail(error))
//   }
// }

// function* onDeleteOrder({ payload: order }) {
//   try {
//     const response = yield call(deleteOrder, order)
//     yield put(deleteOrderSuccess(response))
//   } catch (error) {
//     yield put(deleteOrderFail(error))
//   }
// }

// function* onAddNewOrder({ payload: order }) {
//   try {
//     const response = yield call(addNewOrder, order)
//     yield put(addOrderSuccess(response))
//   } catch (error) {
//     yield put(addOrderFail(error))
//   }
// }

// function* getProductComents() {
//   try {
//     // todo - add product Id to the payload and api
//     const response = yield call(getProductComentsApi)
//     yield put(getProductCommentsSuccess(response))
//   } catch (error) {
//     yield put(getProductCommentsFail(error))
//   }
// }

// function* onLikeComment({ payload: { commentId, productId } }) {
//   try {
//     // todo - add product Id to the payload and api
//     const response = yield call(onLikeCommentApi, commentId, productId)
//     yield put(onLikeCommentSuccess(response))
//   } catch (error) {
//     yield put(onLikeCommentFail(error))
//   }
// }

// function* onLikeReply({ payload: { commentId, productId, replyId } }) {
//   try {
//     // todo - add product Id to the payload and api
//     const response = yield call(onLikeReplyApi, commentId, productId, replyId)
//     yield put(onLikeReplySuccess(response))
//   } catch (error) {
//     yield put(onLikeReplyFail(error))
//   }
// }

// function* onAddReply({ payload: { commentId, productId, replyText } }) {
//   try {
//     const response = yield call(onAddReplyApi, commentId, productId, replyText)
//     yield put(onAddReplySuccess(response))
//   } catch (error) {
//     yield put(onAddReplyFail(error))
//   }
// }

// function* onAddComment({ payload: { productId, commentText } }) {
//   try {
//     const response = yield call(onAddCommentApi, productId, commentText)
//     yield put(onAddCommentSuccess(response))
//   } catch (error) {
//     yield put(onAddCommentFail(error))
//   }
// }

function* eventSaga() {
  yield takeEvery(GET_EVENTS, fetchEvents)
  yield takeEvery(GET_EVENT_DETAIL, fetchEventDetail)
  yield takeEvery(DELETE_EVENT, onDeleteEvent)
  yield takeEvery(UPDATE_EVENT, updateEvent)
  // yield takeEvery(GET_SHOPS, fetchShops)
  // yield takeEvery(ADD_NEW_ORDER, onAddNewOrder)
  // yield takeEvery(UPDATE_ORDER, onUpdateOrder)
  // yield takeEvery(DELETE_ORDER, onDeleteOrder)
  // yield takeEvery(GET_PRODUCT_COMMENTS, getProductComents)
  // yield takeEvery(ON_LIKE_COMMENT, onLikeComment)
  // yield takeEvery(ON_LIKE_REPLY, onLikeReply)
  // yield takeEvery(ON_ADD_REPLY, onAddReply)
  // yield takeEvery(ON_ADD_COMMENT, onAddComment)
}

export default eventSaga
