import React from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor"

const Apaexlinecolumn = ({ dataColors, data }) => {
  const apaexlineColumnColors = getChartColorsArray(dataColors)

  // const series = [
  //   {
  //     name: "Events",
  //     data: [46, 57, 59, 54, 62, 58, 64, 60, 66],
  //   },
  //   {
  //     name: "User",
  //     data: [74, 83, 102, 97, 86, 106, 93, 114, 94],
  //   },
  //   {
  //     name: "Booked Events",
  //     data: [37, 42, 38, 26, 47, 50, 54, 55, 43],
  //   },
  // ]
  const options = {
    chart: {
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: apaexlineColumnColors,
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    // yaxis: {
    //   title: {
    //     text: "$ (thousands)",
    //   },
    // },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands"
        },
      },
    },
  }

  return (
    <ReactApexChart options={options} series={data} type="bar" height={350} />
  )
}

export default Apaexlinecolumn
