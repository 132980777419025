export default function authHeader() {
  const authUser = localStorage.getItem("authUser")
  try {
    const obj = JSON.parse(authUser)

    if (obj && obj.accessToken) {
      return `Bearer ${obj.accessToken}`
    }
  } catch (error) {
    console.error("Error parsing authUser from localStorage:", error)
  }
  return {}
}
