/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

/*UPDATE USER */
export const UPDATE_USERR = "UPDATE_USERR"
export const UPDATE_USERR__SUCCESS = "UPDATE_USERR__SUCCESS"
export const UPDATE_USERR_FAIL = "UPDATE_USERR_FAIL"

/*USER DETAILS */
export const GET_USER_DETAIL = "GET_USER_DETAIL"
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS"
export const GET_USER_DETAIL_FAIL = "GET_USER_DETAIL_FAIL"

/**
 * USER DELETE
 */
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"
