/* Puchase Event */
export const GET_PURCHASEEVENT = "GET_PURCHASEEVENT"
export const GET_PURCHASEEVENT_SUCCESS = "GET_PURCHASEEVENT_SUCCESS"
export const GET_PURCHASEEVENT_FAIL = "GET_PURCHASEEVENT_FAIL"

/* Purchase Event Apprentice Detail */
export const GET_PURCHASEEVENT_APPRENTICE_DETAIL =
  "GET_PURCHASEEVENT_APPRENTICE_DETAIL"
export const GET_PURCHASEEVENT_APPRENTICE_DETAIL_SUCCESS =
  "GET_PURCHASEEVENT_APPRENTICE_DETAIL_SUCCESS"
export const GET_PURCHASEEVENT_APPRENTICE_DETAIL_FAIL =
  "GET_PURCHASEEVENT_APPRENTICE_DETAIL_FAIL"

/* Delete Purchase Event */
export const DELETE_PURCHASEEVENT = "DELETE_PURCHASEEVENT"
export const DELETE_PURCHASEEVENT_SUCCESS = "DELETE_PURCHASEEVENT_SUCCESS"
export const DELETE_PURCHASEEVENT_FAIL = "DELETE_PURCHASEEVENT_FAIL"
