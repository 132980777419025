import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USER_DETAIL,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  UPDATE_USERR,
  UPDATE_USERR__SUCCESS,
  UPDATE_USERR_FAIL,
} from "./actionTypes"

export const getUsers = () => ({
  type: GET_USERS,
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const updateUserr = (userId, value) => ({
  type: UPDATE_USERR,
  payload: { userId, value },
})

export const updateUserrSuccess = user => ({
  type: UPDATE_USERR__SUCCESS,
  payload: user,
})

export const updateUserrFail = error => ({
  type: UPDATE_USERR_FAIL,
  payload: error,
})

export const getUserDetail = id => ({
  type: GET_USER_DETAIL,
  payload: id,
})

export const getUserDetailSuccess = user => ({
  type: GET_USER_DETAIL_SUCCESS,
  payload: user,
})

export const getUserDetailFail = error => ({
  type: GET_USER_DETAIL_FAIL,
  payload: error,
})

export const deleteUser = id => ({
  type: DELETE_USER,
  payload: id,
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})
