import {
  GET_BOOKEDEVENTS_SUCCESS,
  GET_BOOKEDEVENTS_FAIL,
  GET_BOOKEDEVENT_DETAIL_SUCCESS,
  GET_BOOKEDEVENT_DETAIL_FAIL,
  DELETE_BOOKED_EVENT_SUCCESS,
  DELETE_BOOKED_EVENT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  bookedEvents: [],
  bookedEventsError: {},
  bookedEventDetail: {},
  bookedEventDetailError: {},
  deleteBookedEventError: {},
}

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BOOKEDEVENTS_SUCCESS:
      return {
        ...state,
        bookedEvents: action.payload,
      }
    case GET_BOOKEDEVENTS_FAIL:
      return {
        ...state,
        bookedEventsError: action.payload,
      }
    case GET_BOOKEDEVENT_DETAIL_SUCCESS:
      return {
        ...state,
        bookedEventDetail: action.payload,
      }
    case GET_BOOKEDEVENT_DETAIL_FAIL:
      return {
        ...state,
        bookedEventDetailError: action.payload,
      }
    case DELETE_BOOKED_EVENT_SUCCESS:
      return {
        ...state,
        bookedEvents: state.bookedEvents.filter(
          event => event.id.toString() !== action.payload.toString(),
        ),
      }
    case DELETE_BOOKED_EVENT_FAIL:
      return {
        ...state,
        deleteBookedEventError: action.payload,
      }
    default:
      return state
  }
}

export default Users
