/* BOOKED EVENT */
export const GET_BOOKEDEVENTS = "GET_BOOKEDEVENTS"
export const GET_BOOKEDEVENTS_SUCCESS = "GET_BOOKEDEVENTS_SUCCESS"
export const GET_BOOKEDEVENTS_FAIL = "GET_BOOKEDEVENTS_FAIL"

/* BOOKED EVENT DETAIL */
export const GET_BOOKEDEVENT_DETAIL = "GET_BOOKEDEVENT_DETAIL"
export const GET_BOOKEDEVENT_DETAIL_SUCCESS = "GET_BOOKEDEVENT_DETAIL_SUCCESS"
export const GET_BOOKEDEVENT_DETAIL_FAIL = "GET_BOOKEDEVENT_DETAIL_FAIL"

/* DELETE BOOKED EVENT */
export const DELETE_BOOKED_EVENT = "DELETE_EVENT"
export const DELETE_BOOKED_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS"
export const DELETE_BOOKED_EVENT_FAIL = "DELETE_EVENT_FAIL"
