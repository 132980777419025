import {
  GET_BOOKEDEVENTS,
  GET_BOOKEDEVENTS_SUCCESS,
  GET_BOOKEDEVENTS_FAIL,
  GET_BOOKEDEVENT_DETAIL,
  GET_BOOKEDEVENT_DETAIL_SUCCESS,
  GET_BOOKEDEVENT_DETAIL_FAIL,
  DELETE_BOOKED_EVENT,
  DELETE_BOOKED_EVENT_SUCCESS,
  DELETE_BOOKED_EVENT_FAIL,
} from "./actionTypes"

export const getBookedEvent = () => ({
  type: GET_BOOKEDEVENTS,
})

export const getBookedEventSuccess = booked => ({
  type: GET_BOOKEDEVENTS_SUCCESS,
  payload: booked,
})

export const getBookedEventFail = error => ({
  type: GET_BOOKEDEVENTS_FAIL,
  payload: error,
})

export const getBookedEventDetail = id => ({
  type: GET_BOOKEDEVENT_DETAIL,
  payload: id,
})

export const getBookedEventDetailSuccess = booked => ({
  type: GET_BOOKEDEVENT_DETAIL_SUCCESS,
  payload: booked,
})

export const getBookedEventDetailFail = error => ({
  type: GET_BOOKEDEVENT_DETAIL_FAIL,
  payload: error,
})

export const deleteBookedEvent = id => ({
  type: DELETE_BOOKED_EVENT,
  payload: id,
})

export const deleteBookedEventSuccess = event => ({
  type: DELETE_BOOKED_EVENT_SUCCESS,
  payload: event,
})

export const deleteBookedEventFail = error => ({
  type: DELETE_BOOKED_EVENT_FAIL,
  payload: error,
})
