import React from "react"
import { Col, Row } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"

export default function EventDetails() {
  const { eventDetail } = useSelector(state => ({
    eventDetail: state.events.eventDetail,
  }))

  return (
    <>
      <div className="boxed">
        <div className="heading">
          <h3>Event Details</h3>
        </div>
        <Row className="muiTag">
          <Col md={12}>
            <Col>
              <img src={eventDetail.event_image} alt="" />
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Event Name</h4>
              <span>{eventDetail.name}</span>
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Course Url:</h4>
              <span>{eventDetail.course_url}</span>
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Created By:</h4>
              <span>{eventDetail?.instructor_details?.username}</span>
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Creator Phone Number:</h4>
              <span>{eventDetail.instructor_details?.phone_number}</span>
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Event Type:</h4>
              <span>{eventDetail.event_type_and_schedule_id}</span>
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Custom Questions:</h4>
              <span>
                {eventDetail.custom_questions?.map(el => {
                  return (
                    <div key={el.id}>
                      <p>{el.fieldType}</p>
                      <p style={{ Paddingleft: 10 }}>
                        {el.questionPromptLabel}
                      </p>
                    </div>
                  )
                })}
              </span>
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Cost per spot:</h4>
              <span>{eventDetail.cost_per_spot}</span>
            </Col>
          </Col>
          <Col md={4}>
            <Col>
              <h4>Available Spot:</h4>
              <span>{eventDetail.available_spots}</span>
            </Col>
          </Col>
        </Row>
      </div>
    </>
  )
}
