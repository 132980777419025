import { call, put, takeEvery } from "redux-saga/effects"
import {
  DELETE_PURCHASEEVENT,
  GET_PURCHASEEVENT,
  GET_PURCHASEEVENT_APPRENTICE_DETAIL,
} from "./actionTypes"
import {
  deletePurchaseEventFail,
  deletePurchaseEventSuccess,
  getPurchaseEventSuccess,
  getPurhcaseEventApprenticeFail,
  getPurhcaseEventApprenticeSuccess,
  getPurhcaseEventFail,
} from "./actions"
import { del, get } from "helpers/api_helper"

/* Get Purchase Event Data */
function* fetchPurchaseEvent() {
  try {
    const response = yield call(get, "/api/v1/admin/event/purchases/")
    yield put(getPurchaseEventSuccess(response.results))
  } catch (error) {
    yield put(getPurhcaseEventFail(error))
  }
}

/* Get Purchase Event Apprentice Data */
function* fetchPurchaseEventApprenticeDetails({ payload: event }) {
  try {
    const response = yield call(get, `/api/v1/admin/event/purchases/${event}`)
    yield put(getPurhcaseEventApprenticeSuccess(response.results))
  } catch (error) {
    yield put(getPurhcaseEventApprenticeFail(error))
  }
}

/* Delete Particular(By id) Purchase Event Data */
function* onDeletePurchaseEvent({ payload: event }) {
  try {
    const response = yield call(del, `api/v1/admin/event/manage-event/${event}`)
    yield put(deletePurchaseEventSuccess(event))
  } catch (error) {
    yield put(deletePurchaseEventFail(error))
  }
}

function* PurchaseEventSaga() {
  yield takeEvery(GET_PURCHASEEVENT, fetchPurchaseEvent)
  yield takeEvery(
    GET_PURCHASEEVENT_APPRENTICE_DETAIL,
    fetchPurchaseEventApprenticeDetails,
  )
  yield takeEvery(DELETE_PURCHASEEVENT, onDeletePurchaseEvent)
}

export default PurchaseEventSaga
